import { Link } from "gatsby";
import React, { useEffect } from "react";

function UploadInput({
  fileValue,
  setFilePopUp,
  setNumberOfWords,
  numberOfWords,
  errorThree,
  setErrorThree,
  handleDeleteFile,
  transtalteFromInput,
  storeValue,
  setErrorOne,
  setErrorTwo,
}) {
  // fileValue.reduce((prev, next) => prev + next.noOfWords, 0)

  const handleNumberOfWords = (e) => {
    setNumberOfWords(e.target.value);
    setErrorThree(false);
  };
  function truncateFileName(fileName) {
    const maxLength = 4;
    const [name, extension] = fileName.split(/\.(?=[^.]+$)/);
    if (name.length <= maxLength) return fileName;
    return `${name.substring(0, maxLength)}...${extension}`;
  }
  const validateInput = () => {
    let isValid = true;

    if (!transtalteFromInput) {
      setErrorOne(true);
      isValid = false;
    } else {
      setErrorOne(false);
    }

    if (!storeValue) {
      setErrorTwo(true);
      isValid = false;
    } else {
      setErrorTwo(false);
    }

    return isValid;
  };
  const SubmitSubscribe = () => {
    if (validateInput()) {
      setFilePopUp(true);
      return;
    }
  };

  return (
    <div className=" flex flex-wrap lg:flex-nowrap w-full 2xl:w-11/12 lg:w-11/12 justify-center lg:ml-2 2xl:ml-11">
      <div className="flex flex-col items-center justify-start w-full font-opensans  2xl:ml-[-43px]">
        <span className="text-[18px] w-full text-left   font-opensans">
          Word Count
        </span>
        <div
          className={`w-full flex justify-start items-center font-arial h-[50px]   bg-white pl-4 rounded-xl border  ${
            errorThree ? "border-[#ff0000]" : "border-[#CCCCCC]"
          } border-solid text-[#777777] mb-2`}
        >
          <div className="cursor-pointer w-8/12  font-arial flex">
            {fileValue?.length ? (
              <span
                className={`inline-block  text-lg  ${
                  fileValue?.length === 1 ? "w-[20%]" : "w-[50%]"
                } text-black`}
              >
                {fileValue?.reduce((prev, next) => prev + next.noOfWords, 0)}
              </span>
            ) : (
              <>
                <span
                  className="inline-block text-base w-full text-black "
                  style={{ width: "90%", borderRight: "1px solid #e2e2e2" }}
                >
                  <input
                    type="number"
                    value={numberOfWords !== 0 ? numberOfWords : ""}
                    className="w-full outline-none"
                    placeholder="Enter word count"
                    onChange={handleNumberOfWords}
                  />
                </span>
                <span className="text-base text-[#a3a2a2] px-[10px]">or</span>
              </>
            )}

            <p
              className={
                fileValue?.length !== 0 ? "text-black flex ml-[6px]" : "w-0"
              }
            >
              {fileValue?.length ? (
                <>
                  {fileValue?.length === 1 ? (
                    <>
                      |
                      <span className="flex gap-2 bg-[#F1F6F8] px-2 rounded-md">
                        {truncateFileName(fileValue[0]?.originalName)}

                        <button
                          className="ml-2 "
                          onClick={() => handleDeleteFile(fileValue[0].id)}
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="10"
                            viewBox="0 0 9 10"
                            fill="none"
                          >
                            <path
                              d="M8.86813 8.73142C8.90994 8.77322 8.9431 8.82286 8.96573 8.87748C8.98835 8.9321 9 8.99065 9 9.04977C9 9.1089 8.98835 9.16744 8.96573 9.22207C8.9431 9.27669 8.90994 9.32632 8.86813 9.36813C8.82632 9.40994 8.77669 9.4431 8.72207 9.46573C8.66744 9.48835 8.6089 9.5 8.54977 9.5C8.49065 9.5 8.4321 9.48835 8.37748 9.46573C8.32286 9.4431 8.27322 9.40994 8.23142 9.36813L4.5 5.63615L0.768582 9.36813C0.684149 9.45257 0.569632 9.5 0.450225 9.5C0.330818 9.5 0.216301 9.45257 0.131868 9.36813C0.0474343 9.2837 2.3538e-09 9.16918 0 9.04977C-2.3538e-09 8.93037 0.0474343 8.81585 0.131868 8.73142L3.86385 5L0.131868 1.26858C0.0474343 1.18415 -8.89652e-10 1.06963 0 0.950225C8.89652e-10 0.830818 0.0474343 0.716301 0.131868 0.631868C0.216301 0.547434 0.330818 0.5 0.450225 0.5C0.569632 0.5 0.684149 0.547434 0.768582 0.631868L4.5 4.36385L8.23142 0.631868C8.31585 0.547434 8.43037 0.5 8.54977 0.5C8.66918 0.5 8.7837 0.547434 8.86813 0.631868C8.95257 0.716301 9 0.830818 9 0.950225C9 1.06963 8.95257 1.18415 8.86813 1.26858L5.13615 5L8.86813 8.73142Z"
                              fill="#667495"
                            />
                          </svg>
                        </button>
                      </span>
                    </>
                  ) : (
                    `| ${fileValue?.length} files`
                  )}
                </>
              ) : null}
            </p>
          </div>
          <div>
            {localStorage.getItem("quote_id") && (
              <button
                className="bg-[#00173A] text-white font-opensans text-[13px] px-4 mr-2 rounded-lg font-bold py-1 whitespace-nowrap cursor-pointer"
                // onClick={(e) => {
                //   validateInput();
                //   setFilePopUp(true);
                // }}
                onClick={(e) => {
                  SubmitSubscribe();
                }}
                type="button"
              >
                {fileValue?.length ? "EDIT FILES" : "UPLOAD FILE"}
              </button>
            )}
            {!localStorage.getItem("quote_id") && (
              <p className="bg-[#00173A] text-white font-opensans text-[13px] px-4 mr-2 rounded-lg font-bold py-1 whitespace-nowrap  bg-opacity-30 cursor-not-allowed">
                {fileValue?.length ? "EDIT FILES" : "UPLOAD FILE"}
              </p>
            )}
          </div>
        </div>
        {errorThree && (
          <span className="text-red block mr-auto 2xl:pl-5">
            This field is required.
          </span>
        )}{" "}
        <p className=" text-sm text-[#978B89] w-full text-left font-opensans leading-6 ">
          SSL TLS and PCI DSS Compliant ✔
        </p>
        <p className="text-sm text-[#978B89] w-full text-left font-opensans leading-6 ">
          {" "}
          Check our
          <Link
            to="https://www.tomedes.com/legal/information-security"
            target="_blank"
          >
            <span className="text-orange"> security policy </span>
          </Link>
          to learn how we keep your data safe.
        </p>
      </div>
    </div>
  );
}

export default UploadInput;
